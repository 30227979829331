.text-black {
  color: black;
}

.grad {
  background-image: linear-gradient(
    to right,
    rgb(108, 134, 220),
    rgb(157, 67, 160)
  );
}

.text-app-purple {
  color: rgb(128, 104, 184);
}

.text-app-green {
  color: rgb(75, 177, 130);
}

.text-app-pink {
  color: rgb(233, 119, 170);
}

.text-app-blue {
  color: rgb(53, 136, 210);
}

.bg-app-blue {
  background-color: rgb(72, 153, 224);
}

.bg-app-pink {
  background-color: rgb(233, 119, 170);
}

.cursor-zoom-in {
  cursor: zoom-in;
}

.project-left-section-width {
  width: 35%;
}
.project-right-section-width {
  width: 65%;
}

.about-left-section-width {
  width: 45%;
}
.about-right-section-width {
  width: 55%;
}

.product-screenshot .arrow-button {
  visibility: hidden;
  display: none;
}
.product-screenshot:hover .arrow-button {
  visibility: visible;
  display: flex;
}

.section-padding {
  padding: 70px;
}

.section-top-margin {
  margin-top: 6vw;
}

.company-top-margin {
  margin-top: 7vw;
}

.scroll-smooth {
  scroll-behavior: smooth;
}

.company-section-padding {
  padding: 30px;
}

.experience-logo-display {
  display: block;
}

.status-color-todo {
  background-color: rgb(115, 174, 249);
}
.status-color-working {
  background-color: rgb(246, 208, 94);
}
.status-color-cancel {
  background-color: rgb(230, 124, 120);
}
.status-color-complete {
  background-color: rgb(88, 181, 118);
}

@media only screen and (max-width: 1000px) {
  .project-left-section-width {
    width: 100%;
  }
  .project-right-section-width {
    width: 100%;
  }
  .about-left-section-width {
    width: 100%;
  }
  .about-right-section-width {
    width: 100%;
  }
  .section-padding {
    padding: 24px;
  }
  .section-top-margin {
    margin-top: 50px;
  }

  .company-top-margin {
    margin-top: 60px;
  }

  .company-section-padding {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 25px;
    padding-bottom: 25px;
  }
  .experience-logo-display {
    display: none;
  }
}
